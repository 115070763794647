import React from 'react';
import ConstructionIcon from '@mui/icons-material/Construction';
import DescriptionIcon from '@mui/icons-material/Description';
import HandshakeIcon from '@mui/icons-material/Handshake';

import { TextListWithIcons } from '../basic/TextListWithIcons';
import { ContentMainHeader } from '../basic/ContentMainHeader';
import { ContentParagraph } from '../basic/ContentParagraph';

import { SignupFormWithHelmParams } from './SignupFormWithHelmParams';

export const MainSignupForm = () => (
    <SignupFormWithHelmParams>
        <ContentMainHeader>Start building today</ContentMainHeader>
        <ContentParagraph>
            Get free early access to the Truvity platform and explore the future of secure data and document management.
            Simplify document exchange, ensure authenticity, and automate compliance with verifiable, tamper-proof
            records.
        </ContentParagraph>
        <TextListWithIcons
            title="Get access to:"
            items={[
                {
                    icon: <ConstructionIcon />,
                    content: (
                        <>
                            The building blocks to implement secure and verifiable document workflows, leveraging
                            cryptographic proofs and structured data
                        </>
                    ),
                },
                {
                    icon: <DescriptionIcon />,
                    content: (
                        <>
                            Tamper-proof, machine-readable documents with built-in verification and automation
                            capabilities
                        </>
                    ),
                },
                {
                    icon: <HandshakeIcon />,
                    content: (
                        <>
                            Extensive developer documentation and hands-on support, helping you integrate Truvity’s
                            capabilities into your solutions
                        </>
                    ),
                },
            ]}
        />
    </SignupFormWithHelmParams>
);
