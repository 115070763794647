import React, { PropsWithChildren, useEffect } from 'react';
import Auth0Lock from 'auth0-lock';

import { SignUpFormContainerStyled, SignUpTextContainerStyled, SignUpFormStyled } from './SignupForm.styles';

const LOCK_CONTAINER_ID = 'signup-container';

export type SignupFormProps = {
    auth0Domain: string;
    auth0ClientId: string;
    auth0AllowedConnections?: string[];
    redirectUrl: string;
    logoUrl?: string;
    additionalSignUpFields?: Auth0LockAdditionalSignUpField[];
    stayInTouchText?: string;
};

export const SignupForm = ({
    auth0Domain,
    auth0ClientId,
    auth0AllowedConnections,
    redirectUrl,
    additionalSignUpFields,
    logoUrl,
    stayInTouchText,
    children,
}: PropsWithChildren<SignupFormProps>) => {
    useEffect(() => {
        const lock = new Auth0Lock(auth0ClientId, auth0Domain, {
            container: LOCK_CONTAINER_ID,
            allowedConnections: auth0AllowedConnections,
            allowLogin: false,
            allowSignUp: true,
            initialScreen: 'signUp',
            mustAcceptTerms: true,
            signUpFieldsStrictValidation: true,
            avatar: null,
            theme: {
                primaryColor: '#005ad7',
                logo: logoUrl ?? `${process.env.PUBLIC_URL}/truvity.svg`,
            },
            languageDictionary: {
                signUpTerms:
                    'By signing up, you agree to our <a href="https://truvity.com/terms" _target="blank">terms & conditions</a> and <a href="https://truvity.com/privacy" _target="blank">privacy policy</a>.',
            },
            auth: {
                redirect: true,
                redirectUrl,
            },
            additionalSignUpFields: [
                {
                    type: 'checkbox',
                    name: 'stay_in_touch',
                    prefill: 'false',
                    placeholder: stayInTouchText ?? 'Stay in touch with Truvity',
                },
                ...(additionalSignUpFields ?? []),
            ],
        });

        lock.show();

        return () => lock.hide();
    }, [
        additionalSignUpFields,
        auth0AllowedConnections,
        auth0ClientId,
        auth0Domain,
        logoUrl,
        redirectUrl,
        stayInTouchText,
    ]);

    return (
        <SignUpFormContainerStyled>
            <SignUpFormStyled id={LOCK_CONTAINER_ID} />
            <SignUpTextContainerStyled>{children}</SignUpTextContainerStyled>
        </SignUpFormContainerStyled>
    );
};
